class Storage {
  setItem(key, value, storageType = "sessionStorage") {
    if (typeof window !== "undefined") {
      window[storageType].setItem(key, JSON.stringify(value))
    }
  }

  getItem(key, storageType = "sessionStorage") {
    if (typeof window !== "undefined") {
      const saved = window[storageType].getItem(key)
      return saved !== null ? JSON.parse(saved) : ""
    }
  }
}

const storageSync = new Storage()

export default storageSync
