import { Link, useLocation } from "@reach/router"
import { navigate } from "gatsby"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { ENDPOINTS } from "../../api/endpoints"
import { useFetch } from "../../hooks/useFetch"
import Logo from "../../images/svg/logo-header.svg"
import LanguageMenu from "../locale-switch/locale-switch"
import "./header.mobile.scss"
import "./header.scss"
import "./header.tablet.scss"

const Header = () => {
  const { i18n } = useTranslation()
  const location = useLocation()

  const collaspsedRef = React.useRef(null)
  const [expended, setExpanded] = useState(false)
  const [colorChange, setColorchange] = useState(false)
  const { response } = useFetch(ENDPOINTS.HEADER, i18n.language)

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true)
    } else {
      setColorchange(false)
    }
  }

  React.useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", changeNavbarColor)
    }

    return () => {
      window.removeEventListener("scroll", changeNavbarColor)
    }
  }, [])

  const containerRef = React.useRef(null)
  const menuToggler = React.useRef(null)

  const toggleHeaderClass = useCallback(() => {
    if (containerRef?.current.classList.value.includes("header-dark")) {
      containerRef.current?.classList.remove("header-dark")
      menuToggler?.current.classList.add("collapsed")
      collaspsedRef?.current.classList.remove("show")
      setExpanded(false)
    } else {
      containerRef?.current.classList.add("header-dark")
      menuToggler?.current.classList.add("collapsed")
      setExpanded(true)
    }
  }, [expended])

  React.useEffect(() => {
    setExpanded(false)
    containerRef.current?.classList.remove("header-dark")
    menuToggler?.current.classList.add("collapsed")
    collaspsedRef?.current.classList.remove("show")
  }, [location])

  const closeMenuAndRemoveBackground = () => {
    containerRef?.current?.classList.remove("header-dark")
    menuToggler?.current.classList.add("collapsed")
    collaspsedRef?.current.classList.remove("show")
  }

  return (
    <div
      className={
        colorChange
          ? "container-fluid navbar-container sticky-nav bg-visible"
          : "container-fluid navbar-container sticky-nav "
      }
      ref={containerRef}
    >
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light navbar__custom  sticky-nav">
          <div className="container-fluid container__custom">
            <div className="logo-wrapper nav-item">
              <Logo className="navbar-logo" onClick={() => navigate("/")} />
            </div>

            <button
              ref={menuToggler}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded={expended}
              aria-label="Toggle navigation"
              role="button"
              aria-hidden
              onClick={() => toggleHeaderClass()}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse collapse__custom"
              ref={collaspsedRef}
              id="navbarNavDropdown"
            >
              <ul className="navbar-nav navbar-nav__custom">
                {response?.data?.attributes?.navigation.map(navItem => (
                  <li className="nav-item" key={navItem.path}>
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to={"/page/" + navItem.path.split("/")[2]}
                      onClick={() => closeMenuAndRemoveBackground()}
                    >
                      {navItem.label}
                    </Link>
                  </li>
                ))}
                {location?.pathname.includes("news/art/") ? null : (
                  <li className="nav-item">
                    <LanguageMenu></LanguageMenu>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
