import { useEffect, useState } from "react"
import storageSync from "../services/storage"

export const API_URL = "https://my-app-ebdcd.ondigitalocean.app"

export const useFetch = (endpoint, locale, paginationQuery = "") => {
  const [status, setStatus] = useState("IDLE")
  const [response, setResponse] = useState(null)

  useEffect(() => {
    if (!endpoint || !locale) return

    const fetchDataa = async () => {
      const localesPart = `?locale=${locale}&${paginationQuery}`
      const url = API_URL + endpoint + localesPart
      const cache = storageSync.getItem("API_REF") || {}

      setStatus("FETCHING")

      if (cache[url]) {
        const data = cache[url]

        setResponse(data)
        setStatus("FETCHED from localStorage")
      } else {
        const response = await fetch(url)
        const data = await response.json()
        cache[url] = data
        storageSync.setItem("API_REF", cache)

        setResponse(data)

        setStatus("FETCHED from API")
      }
    }
    fetchDataa()
  }, [endpoint, paginationQuery, locale])

  return { status, response }
}
