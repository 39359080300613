import storageSync from "../services/storage"

export const API_URL = "https://my-app-ebdcd.ondigitalocean.app"
export const FRONTEND_URL = "https://greenlanes.pl"

export const Locales = {
  PL: "pl-PL",
  EN: "en-GB",
}

export class ApiService {
  static async get(
    ENDPOINT,
    locale = storageSync.getItem("locale"),
    paginationQuery = ""
  ) {
    const localesPart = `?locale=${locale}&${paginationQuery}`

    const url = API_URL + ENDPOINT + localesPart

    return fetch(url).then(response => {
      return response.json()
    })
  }
}
