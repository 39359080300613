import React from "react"
import { withTrans } from "../i18n/withTrans"
import Header from "./header/header"
import "./layout.scss"
import "./styles/base.scss"

const Layout = ({ children }) => {
  return (
    <>
      <div className="main-wrapper">
        <Header siteTitle={"Title"} />
        <main>{children}</main>
      </div>
    </>
  )
}

export default withTrans(Layout)
