import i18next from "i18next"
import { Locales } from "../api/api-service"

i18next.init({
  fallbackLng: Locales.PL,
  resources: {
    pl: {
      translations: require(`../locale/${Locales.PL}/translations.json`),
    },
    en: {
      translations: require(`../locale/${Locales.EN}/translations.json`),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,

  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
    useSuspense: true,
    transSupportBasicHtmlNodes: true,
  },
})

i18next.languages = [Locales.PL, Locales.EN]

export default i18next
