import { useLocation } from "@reach/router"
import classNames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Locales } from "../../api/api-service"
import "./locale-switch.scss"

const LanguageMenu = () => {
  const { i18n } = useTranslation()
  const location = useLocation()

  const [visible, setVisible] = useState(true)

  const changeLanguage = useCallback(() => {
    const newLocaleValue = i18n.language == Locales.EN ? Locales.PL : Locales.EN
    i18n.changeLanguage(newLocaleValue)
  }, [i18n.language])

  useEffect(() => {
    if (location.pathname.includes("news/art")) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }, [location])

  return (
    <div
      className={classNames({
        "nav-link": true,
        "locale-switcher": true,
        show: visible,
      })}
      onClick={() =>
        changeLanguage(i18n.language == Locales.EN ? Locales.PL : Locales.EN)
      }
    >
      {i18n.language == Locales.EN ? "pl" : "en"}
    </div>
  )
}

export default LanguageMenu
