export const ENDPOINTS = {
  HEADER: "/api/header",
  ABOUT_US_PAGE: "/api/about-us-page",
  ARTICLES: "/api/articles",
  CONTACT_PAGE: "/api/contact-page",
  FILTERS: "/api/filters",
  FOOTER: "/api/footer",
  HOME_PAGE: "/api/home-page",
  PRIVACY_POLICY_PAGE: "/api/privacy-policy-page",
  NEWS_PAGE: "/api/news-page",
  PORTFOLIO_PAGE: "/api/portfolio-page",
  SUSTAINABILITY_PAGE: "/api/sustainability-page",
  TAGS: "/api/tags",
  TEAM_MEMBERS: "/api/team-members",
  LOCALES: "/api/i18n/locales",
}
